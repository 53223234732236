import React from 'react';

import FormattedHTMLMessage from '../components/shared/FormattedHTMLMessage';

function t(key, props = {}) {
  return window.Strava.I18n.Locale.t(key, props);
}

function elevationFormatter(unitSystem) {
  return new window.Strava.I18n.ElevationFormatter(unitSystem);
}

function elevationLabelFormatter(unitSystem) {
  return new window.Strava.I18n.ChartLabelElevationFormatter(unitSystem);
}

function distanceFormatter(unitSystem) {
  return new window.Strava.I18n.DistanceFormatter(unitSystem);
}

function distanceLabelFormatter(unitSystem) {
  return new window.Strava.I18n.ChartLabelDistanceFormatter(unitSystem);
}

function distancePerTimeFormatter(unitSystem) {
  return new window.Strava.I18n.DistancePerTimeFormatter(unitSystem);
}

function distancePerTimeLabelFormatter(unitSystem) {
  return new window.Strava.I18n.ChartLabelDistancePerTimeFormatter(unitSystem);
}

function percentFormatter() {
  return new window.Strava.I18n.PercentFormatter();
}

function timespanFormatter() {
  return new window.Strava.I18n.TimespanFormatter();
}

export default {
  t,
  t_html(key, props = {}, options = {}) {
    return <FormattedHTMLMessage {...options} message={t(key, props)} />;
  },
  elevationFormatter,
  elevationLabelFormatter,
  distanceFormatter,
  distanceLabelFormatter,
  distancePerTimeFormatter,
  distancePerTimeLabelFormatter,
  percentFormatter,
  timespanFormatter,
  language() {
    return window.Strava.I18n.Locales.LANGUAGE_CODE;
  },
  measurementUnits() {
    return window.Strava.I18n.UnitSystem.get();
  }
};
