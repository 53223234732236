import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'utils/I18n';
import * as ContainerContext from '@strava/container-context';
import {
  logError,
  logMessage,
  setTag,
  setTags,
  setContext,
  withScope
} from 'utils/sentry';

const {
  AnalyticsContext,
  ErrorLoggingContext,
  TranslationContext,
  ExperimentContext
} = ContainerContext;

const { Provider: AnalyticsProvider } = AnalyticsContext;
const { Provider: TranslationProvider } = TranslationContext;
const { Provider: ErrorLoggingProvider } = ErrorLoggingContext;
const { Provider: ExperimentProvider } = ExperimentContext;

const analyticsContext = {
  track: (data) => window.Strava.ExternalAnalytics.trackV2(data)
};

const translationContext = {
  t: (key, props = {}) => I18n.t(key, props),
  getLanguage: () => I18n.language()
};

const errorLoggingContext = {
  logError,
  logMessage,
  setContext,
  setTag,
  setTags,
  withScope
};

// does not include the branch context
const StravaPackageContextProviders = ({ children }) => (
  <AnalyticsProvider value={analyticsContext}>
    <TranslationProvider value={translationContext}>
      <ErrorLoggingProvider value={errorLoggingContext}>
        <ExperimentProvider value={{ getExperiment: () => {} }}>
          {children}
        </ExperimentProvider>
      </ErrorLoggingProvider>
    </TranslationProvider>
  </AnalyticsProvider>
);

StravaPackageContextProviders.propTypes = {
  children: PropTypes.node.isRequired
};

export default StravaPackageContextProviders;
